.countries {
  text-align: center;

  .radio-container {
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    background: $color-3;
    border-radius: 20px;
    padding: 10px;
    color: $white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.452);

    input {
      transform: translateY(1px);
    }

    label {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  input[type="text"] {
    padding: 10px;
    border-radius: 40px;
    font-size: 1.3rem;
    border: 3px solid $color-1;
    outline: none;
    transition: 0.4s;
    text-align: center;
    width: 400px;
    margin-bottom: 20px;

    &:focus {
      background: $color-1;
      border-radius: 4px;
      color: $white;
    }
  }

  button {
    margin: 0 auto 20px;
    background: none;
    border: 2px solid $color-1;
    padding: 4px 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: $color-1;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
