.article {
  margin: 10px 50px;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px;

  .card-header {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 10px;

    em {
      font-size: 0.8rem;
    }
  }
  p {
    font-size: 0.9rem;
  }
  textarea {
    width: 100%;
    resize: none;
    height: 70px;
    outline: none;
  }
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 0 5px;

    button {
      width: 70px;
      height: 20px;
      font-size: 0.7rem;
      margin: 0 2px;
      cursor: pointer;

      &:hover {
        filter: brightness(110%);
      }
    }
  }
}
