.navigation {
  ul {
    display: flex;
    margin: 30px;

    a {
      text-decoration: none;
      padding: 10px;

      li {
        color: $color-1;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
          color: $color-3;
        }
      }
    }

    .nav-active {
      position: relative;

      &::after {
        content: "";
        height: 4px;
        width: 0;
        background: $color-1;
        position: absolute;
        border-radius: 10px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-animation: anim 0.3s ease forwards;
        animation: anim 0.6s ease forwards;

        @-webkit-keyframes anim {
          to {
            width: 100%;
          }
        }
        @keyframes anim {
          to {
            width: 100%;
          }
        }
      }
    }
  }
}
